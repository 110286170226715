<!-- 绑定实体账户 -->
<template>
	<div v-loading="loading" :class="themeClass" class="contBox width-fill bg-fff margin-t-10">
		<div class="form-Box">
			<div class="title flex-row-align-center text-center">
				<i class="iconfont icon-biaodan font-size30"></i>
				<div class="font-size16">绑定实体账户</div>
			</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="145px" class="demo-ruleForm">
				<el-form-item label="统一社会信用代码">
					<el-input v-model="userinfo.fIdentityCode" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="开户行" prop="fBankTypeID">
					<el-select v-model="ruleForm.fBankTypeID" placeholder="请选择开户行" @change="selectChange">
						<el-option v-for="(item, index) in bankList" :key="index" :label="item.fBankTypeName" :value="item.fBankTypeID"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="银行账户" prop="fBankAccounts">
					<el-input v-model="ruleForm.fBankAccounts" maxlength="32"  @input="newselectChange"></el-input>
				</el-form-item>
				<!-- <el-form-item label="法人姓名" prop="fLegalPerson">
					<el-input v-model="ruleForm.fLegalPerson" ></el-input>
				</el-form-item>
				<el-form-item label="法人身份证号" prop="fLegalPersonCertificateNumber">
					<el-input v-model="ruleForm.fLegalPersonCertificateNumber"></el-input>
				</el-form-item>
				<el-form-item label="法人手机号" prop="fLegalPersonPhone">
					<el-input v-model="ruleForm.fLegalPersonPhone"></el-input>
				</el-form-item> 
				 <el-form-item label="" id="setform" prop="radio">
					<div style="line-height: 20px !important;">
						<el-checkbox-group v-model="ruleForm.radio">
							<el-checkbox label="我已阅读并同意"></el-checkbox>
						</el-checkbox-group>
						<span v-for="(item,index) in zftAgreement" :key='index' style="display: inline-block;line-height: 20px;" >
							<span style="cursor: pointer;" class="color-theme font-size12 lh21" @click="showAgreeDia(item.fAgreementHistID)">《{{item.fTitle}}》</span>
						</span>
					</div>
				</el-form-item>		 -->
				<el-form-item>
					<el-button class="background-color-theme" @click="submitForm('ruleForm')">确定</el-button>
				</el-form-item>
			</el-form>
			<div class="bg-box"></div>
		</div>
		<el-dialog title="激活提现卡" :visible.sync="dialogFormVisible">
			<el-form :model="dialogruleForm" :rules="dialogrules" ref="dialogruleForm">
				<el-form-item label="充值金额" :label-width="formLabelWidth" prop="money">
					<el-input v-model="dialogruleForm.money" autocomplete="off" :required="true"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="validateCancel('dialogruleForm')">取 消</el-button>
				<el-button type="primary" @click="validateConfirm('dialogruleForm')">确 定</el-button>
			</div>
		</el-dialog>
		<agreementDialog :iContents="iContent" ref="areDialog"></agreementDialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import IdentityCodeValid from '@/utils/checkIdent';
	import agreementDialog from '@/components/agreementDialog';
	export default {
		data() {
			var checkMoney = (rule, value, callback) => {
				if (!/^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/.test(value)) {
					callback(new Error('金额必须为数字值且最多保留两位小数'));
				} else {
					callback();
				}
			};
			//   身份证号码验证规则
			let checkRegNo = (rule, value, callback) => {
				if (!IdentityCodeValid(value)) {
					callback(new Error('请输入正确的身份证'));
				} else {
					callback();
				}
			};
			// 验证手机号的校验规则
			let checkMobile = (rule, value, callback) => {
				// 验证手机号的正则表达式
				const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
				if (value == '') {
					callback(new Error('手机号码不能为空'));
				} else if (regMobile.test(value)) {
					// 合法的手机号
					return callback();
				}
				// 不合法
				callback(new Error('请输入正确的手机号'));
			};

			return {
				fromType: 'Add',
				zftAgreement: [],
				dialogFormVisible: false,
				formLabelWidth: '80px',
				dialogruleForm: {
					money: null
				},
				dialogrules: {
					money: [{
						validator: checkMoney,
						trigger: 'blur'
					}]
				},
				loading: true,
				title: '',
				userinfo: {},
				bankList: [], //开户行
				certificateType: [],
				verificationCodeText: '获取验证码',
				ss: '',
				fLegalPerson: '',
				ruleForm: {
					radio: false,
					fIdentityCode: '',
					fLegalPerson: '',
					fCertificateTypeCode: '',
					fLegalPersonCertificateNumber: '',
					fLegalPersonPhone: ''
				},
				rules: {
					fBankAccounts: [{
						required: true,
						message: '请输入银行账户',
						trigger: 'blur'
					},{
						min: 12,
						message: '银行账号不少于12位',
						trigger: 'blur'
					}],
					fLegalPerson: [{
						required: true,
						message: '请输入法人姓名',
						trigger: 'blur'
					}],

					fBankTypeID: [{
						required: true,
						message: '请选择开户行',
						trigger: 'change'
					}],
					fLegalPersonCertificateNumber: [{
							required: true,
							message: '请输入正确的身份证',
							trigger: 'blur'
						},
						{
							validator: checkRegNo,
							trigger: 'blur'
						}
					],
					fLegalPersonPhone: [{
							required: true,
							message: '请输入正确的手机号',
							trigger: 'blur'
						},
						{
							validator: checkMobile,
							trigger: 'blur'
						}
					],
					radio: [{
						required: true,
						validator: (rule, value, callback) => {
							if (value != true) {
								callback(new Error('请阅读并同意开通账服通协议'));
							} else {
								callback();
							}
						},
						trigger: 'change'
					}]
				},
				obj: null,
				noticeShow: false,
				iContent: ''
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			...mapGetters(['getUserInfo'])
		},
		components: {
			agreementDialog
		},
		created() {
			// this.ruleForm = {}
			this.getInfo();
			this.getCertificateType();

			this.loading = false;
		},
		mounted() {
			if (this.$route.query.Type == 'Edit') {
				this.fromType = this.$route.query.Type;
			}
			this.getBankList(this.$route.query.ID);
		},
		methods: {
			selectChange() {
				this.$forceUpdate();
			},
			//对输入的账号进行处理
			newselectChange(){
				this.ruleForm.fBankAccounts = this.ruleForm.fBankAccounts.replace(/[\W]/g, '');
			},
			//获取账服通协议列表
			// getReadAgreement() {
			// 	this.ApiRequestPostNOMess('api/mall/ebbase/agreement-history/get-by-asc-list', {}).then(
			// 		result => {
			// 			if (result.obj) {
			// 				this.zftAgreement = result.obj.items;
			// 			}
			// 		},
			// 		rej => {}
			// 	);
			// },
			closeNoticeShows() {
				this.noticeShow = false;
			},
			shows() {
				this.noticeShow = true;
			},
			showAgreeDia(a) {
				this.noticeShow = true;
				this.iContent = a;
				this.$refs.areDialog.show();
			},
			//   获取用户信息
			getInfo() {
				this.ApiRequestPost('/api/mall/ebcustomer/baseinfo/get-by-current-user-customer-base-info-full', {}).then(
					result => {
						//console.log(result, '00000000000');
						this.userinfo = result.obj;
					},
					rej => {}
				);
			},
			//   获取开户行下拉框数据
			getBankList(id) {
				this.ApiRequestPost('/api/mall/ebbase/bank-type/get-acs-banktype', {}).then(
					result => {
						this.bankList = result.obj.items;
						console.log('this.bankList', this.bankList);
						if (this.fromType == 'Edit') {
							this.getEditInfo(id);
						}
					},
					rej => {}
				);
			},
			//   获取账服通证件类型
			getCertificateType() {
				this.ApiRequestPost('/api/mall/ebbase/certificate-type-external-system/get-acs-list', {}).then(
					result => {
						//console.log(result, '00000000000');
						this.certificateType = result.obj.items;
					},
					rej => {}
				);
			},
			submitForm(formName) {
				this.obj = {};
				if (this.fromType == 'Add') {
					this.$refs[formName].validate(valid => {
						if (valid) {
							this.ApiRequestPostNOMess('/api/mall/ebcustomer/bank-account/create-acceptance-withdraw', {
								fAccountNumber: this.ruleForm.fBankAccounts,
								fBankTypeID: parseInt(this.ruleForm.fBankTypeID)
							}).then(
								result => {
									this.$message({
										message: result.message,
										type: 'info'
									});
								},
								rej => {}
							);
						} else {
							return false;
						}
					});
				} else {
					this.$refs[formName].validate(valid => {
						if (valid) {
							this.ApiRequestPutNOMess('/api/mall/ebcustomer/bank-account/update', {
								fStatus: this.ruleForm.fStatus,
								fIsDefault: this.ruleForm.fIsDefault,
								fAccountID: this.ruleForm.fAccountID,
								fCustomerID: this.ruleForm.fCustomerID,
								fPaymentChannelsID: this.ruleForm.fPaymentChannelsID,
								fBankAccountTypeID: this.ruleForm.fBankAccountTypeID,
								fBankName: this.ruleForm.fBankName,
								fTxSNBinding: this.ruleForm.fTxSNBinding,
								fAccountName: this.ruleForm.fAccountName,
								fAccountNumber: this.ruleForm.fBankAccounts,
								fRemark: this.ruleForm.fRemark,
								frv: this.ruleForm.frv,
								rowStatus: this.ruleForm.rowStatus
							}).then(
								result => {
									this.$message({
										message: result.message,
										type: 'info'
									});
									this.$router.go(-1);
								},
								rej => {}
							);
						} else {
							return false;
						}
					});
				}
			},
			// 验证弹框点击确定
			validateConfirm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.ApiRequestPostNOMess('/api/mall/ebcustomer/bank-account/validatie-acs-b2b-account', {
							fMoney: Number(this.dialogruleForm.money),
							fAccountID: this.obj.fAccountID
						}).then(
							result => {
								if (result.code == 'HengHeCode1000') {
									this.$router.push({
										path: '/businessme/AccountPay'
									});
								} else {
									this.$message({
										message: result.message,
										type: 'error'
									});
								}
							},
							rej => {}
						);
					} else {
						return false;
					}
				});
			},
			// 验证弹框点击取消
			validateCancel(formName) {
				this.$refs[formName].resetFields();
				this.dialogFormVisible = false;
				this.$router.push({
					path: '/businessme/AccountPay'
				});
			},

			//编辑跳转获取表单
			getEditInfo(info) {
				this.ApiRequestPostNOMess('/api/mall/ebcustomer/bank-account/get', {
					id: info
				}).then(
					res => {
						let id = '';
						for (let item of this.bankList) {
							if (res.obj.fBankName == item.fBankTypeName) {
								id = item.fBillTypeID;
							}
						}
						this.ruleForm = res.obj;
						this.ruleForm.fBankTypeName = res.obj.fBankName;
						this.ruleForm.fBankAccounts = res.obj.fAccountNumber;
						this.ruleForm.fBankTypeID = res.obj.fBankAccountTypeID;
					},
					rej => {}
				);
			}
		}
	};
</script>

<style lang="scss" scoped="scoped">
	::v-deep .el-dialog {
		width: 500px;
	}

	//协议复选框样式
	::v-deep .el-checkbox-group {
		width: fit-content !important;
		display: inline !important;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.icon-biaodan {
		color: #1c9dff;
	}

	/deep/ .el-form-item__label {
		padding-right: 19px;
	}

	/deep/ .el-input__inner {
		width: 380px;
		height: 32px;
		line-height: 32px;
		padding: 0 10px;
		border: 1px solid #ccc !important;
	}

	.title {
		height: 20px;
		line-height: 20px;
		margin: 55px auto 16px;
		margin-left: 384px;
	}

	.contBox {
		overflow: hidden;
	}

	.demo-ruleForm {
		// width: 462px;
		margin: 0px auto;
		margin-left: 180px;
		position: relative;
		z-index: 1;
	}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.el-button {
		width: 160px;
		height: 40px;
	}
</style>
